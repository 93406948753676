import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import { DesignUtilityServices } from '../../../appServices/design-utility.service';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { AuthService } from '../../../appServices/auth/auth.service';

@Component({
  selector: 'app-landing',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    FormsModule,
    MatIconModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule
  ],
  templateUrl: './landing.component.html',
  styleUrl: './landing.component.scss'
})
export class LandingComponent implements OnInit {
  Form!: FormGroup;
  usernameType: any = '';
  validator:any;
  loading:boolean = false;

  constructor(
    private _fb: FormBuilder,
    private authService: AuthService,
    private _du: DesignUtilityServices
  ){
    this.validator = this._du.validator;
  }

  ngOnInit(): void {
    this.initForm();
  }



  /**
   * init
   */
  initForm() {
    this.Form = this._fb.group({
      username: [
        '',
        [
          Validators.required,
          Validators.pattern(this.validator[this.usernameType])
        ],
      ],
      countryName:['USA', Validators.required]
    });
  }


  /**
   * Change Input Type based on username (mobile or email)
   */
  changeInputType() {
    if (!this.Form.value.username.length) {
      this.usernameType = null;
    } else if (typeof +this.Form.value.username === "number" && !isNaN(+this.Form.value.username)) {
      this.usernameType = 'mobile';
    } else {
      this.usernameType = 'email';
      // this.setCountryByGeo();
    }
    this.updateValidator();
  }



  /**
   * Update Validator Dynamically
   */
  updateValidator() {
    if (this.usernameType === 'mobile') {
      this.Form.get('username')?.setValidators(
        [
          Validators.required,
          Validators.pattern(`^((\\+91-?)|0)?([0-9]{10})$`),
          Validators.minLength(10),
          Validators.maxLength(10)
        ]
      );

    } else if (this.usernameType === 'email') {
      this.Form.get('username')?.setValidators(
        [
          Validators.required,
          Validators.pattern(this.validator[this.usernameType]),
          Validators.minLength(6)
        ]
      );

    } else {
      this.Form.get('username')?.setValidators([Validators.required, Validators.minLength(6)]);
    }
    this.Form.get('username')?.updateValueAndValidity();
    // this.Form.updateValueAndValidity();
  }

  onSubmit(){
    if (this.Form.invalid) {
      return;
    }
    this.loading = true;
    let finalData: any = {};
    if (this.usernameType==='mobile') {
      // finalData.username = "1" + this.Form.value.username;
      finalData.username = this.Form.value.username;
      finalData.countryName = this.Form.value.countryName;
    } else {
      finalData.username = this.Form.value.username;
      finalData.countryName = this.Form.value.countryName;
    }
    
    this.checkCustomerAndSetUserName(finalData);
  }



  /**
   * Check Customer And Set UserName
   * @param data
   */
  checkCustomerAndSetUserName(data:any){
    this.authService.setUserName(data);
    this.authService.checkCustomer(data).subscribe(
      (res)=>{
          if (!res) {
            this.authService.updateAuthStep('otp-signup');
          }else{
            console.log(res)
            if(res.hasPassword){
              this.authService.updateAuthStep('login-by-password');
            }else{
              this.authService.updateAuthStep('set-password');
            }
          }
          this.loading = false;
          // this.goNextStep(res);
        },
        (err) =>{
          this.loading = false;
          this._du.openCustomSnackBar('Error: '+err, 'Close', 'error');
        }
    );
  }


   /**
   * Go Next Step
   */
  //  goNextStep(res:any) {
  //   if (!res) {
  //     this.authService.updateAuthStep('otp-signup');
  //   } else {
  //     this.basicInfoService.clearBasicInfo();
  //     this._tenantResolver.setTenantName(res);
  //     this.basicInfoService.getBasicInfo();
  //     this.authService.updateAuthStep('login-by-password');
  //   }
  // }


}
