import { Injectable } from '@angular/core';
import { map, Observable, of } from 'rxjs';
import { HttpConfigService } from '../../core/httpConfig/httpConfigService';
import { IProduct } from '../../appInterfaces/IProduct';


@Injectable({
  providedIn: 'root'
})
export class MenuService {

  api:string;
  cachedFavoriteProducts!:IProduct[];

  constructor(
    private apiConfig: HttpConfigService,
  ) {
      this.api = this.apiConfig.getBaseUrl;
    }



  /**
   * Get featured products
   * @param fromDate
   * @param toDate
   * @param pageSize
   * @param pageIndex
   * @returns
   */
  getFeaturedProducts(fromDate: number, toDate: number, pageSize: number=5, pageIndex: number=0): Observable<any> {
    return this.apiConfig.get(`${this.api}reports/product-report/${fromDate}/${toDate}/${pageSize}/${pageIndex}`);
  }



  /**
   * Get categories with products
   * @param menuId
   * @returns
   */
  getCategoriesWithProducts(menuId: number): Observable<any> {
    return this.apiConfig.get(this.api + 'category/getAll/parentCategory/'+menuId + '/customer');
  }

  getProductsByCategoryId(id: number): Observable<any>{
    return this.apiConfig.get(this.api + 'product/category/' + id + "?client=customer");
  }

  getProductByProductId(productId: number): Observable<any>{
    return this.apiConfig.get(this.api + 'product/' + productId);
  }

  /**
   * Get frequently bought products
   * @param size
   * @param productId
   * @returns
   */
   getFrequentlyBoughtProducts(size: number, productId: number): Observable<any> {
      return this.apiConfig.get(this.api + 'product/frequently-bought/' + size + '/' + productId);
   }


  /**
   * get products by points range
   * @param menuId 
   * @param min 
   * @param max 
   * @returns 
   */
  getProductsByPointsRange(menuId: number, min: number, max: number): Observable<any> {
    return this.apiConfig.get(this.api + 'product/loyaltyPoints/'+menuId + '/' +min + '/' +max);
  }


  /**
   * Get all favorite products
   * @param userId 
   * @returns 
   */
  getAllFavoriteProducts(userId:number|undefined): Observable<any> {
    if (this.cachedFavoriteProducts) {
      return of(this.cachedFavoriteProducts);
    } else {
      return this.apiConfig.get(`${this.api}product/favorite/${userId}`).pipe(
        map(response => this.cachedFavoriteProducts = response)
      );
    }
  }


  setCachedFavoriteProducts(products:IProduct[]){
    this.cachedFavoriteProducts = products;
  }

  
  updateFavorite(productId:number, userId: number, active:boolean): Observable<any> {
    return this.apiConfig.patch(`${this.api}product/favorite/${productId}/${userId}/${active}`, { withCredentials: true });
  }


  /**
   * Get products by search
   * @param search 
   * @param menuId 
   * @returns 
   */
  getProductsBySearch(search: string, menuId:number): Observable<any> {
    return this.apiConfig.get(`${this.api}product?search=${search}&menuId=${menuId}&client=customer`);
  }

}
