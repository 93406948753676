<div class="dialog-wrapper">
    <button (click)="goBack()" mat-icon-button class="back">
        <mat-icon>arrow_back</mat-icon>
    </button>
    <button (click)="onNoClick()" mat-icon-button class="close">
        <mat-icon>close</mat-icon>
    </button>
    @if(authStep=='landing'){
        <app-landing/>
    }@else if (authStep=='otp-signup') {
        <app-otp-signup/>
    }@else if (authStep=='login-by-password') {
        <app-login-by-password/>
    }@else if (authStep=='login-by-otp') {
        <app-login-by-otp/>
    }@else if (authStep=='forget-password') {
        <app-forget-password/>
    }@else if (authStep=='set-password') {
        <app-forget-password [setPassword]="true"/>
    }
</div>