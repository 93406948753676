import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { DesignUtilityServices } from '../../../appServices/design-utility.service';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { OtpFieldComponent } from '../../otp-field/otp-field.component';
import { AuthService } from '../../../appServices/auth/auth.service';
import { environment } from '../../../../environments/environment';
import { IBasicInfo } from '../../../appInterfaces/IBasicInfo';
import { BasicInfoService } from '../../../appServices/basic-info/basic-info.service';

@Component({
  selector: 'app-forget-password',
  standalone: true,
  imports: [
    OtpFieldComponent,
    ReactiveFormsModule,
    FormsModule,
    MatIconModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule
  ],
  templateUrl: './forget-password.component.html',
  styleUrl: './forget-password.component.scss'
})
export class ForgetPasswordComponent implements OnInit {
  Form!: FormGroup;
  validator: any;
  loading: boolean = false;
  hide: boolean = true;
  S3_URL = environment.S3_URL;
  basicInfo!: IBasicInfo | null;

  @Input() setPassword: boolean = false;


  constructor(
    private _fb: FormBuilder,
    private _du: DesignUtilityServices,
    private basicInfoService: BasicInfoService,
    private authService: AuthService,

  ) {
    this.validator = this._du.validator;
  }

  ngOnInit(): void {
    this.getBasicInfo();
    this.initForm();
    this.onOtpRequest();
  }


  getBasicInfo(): void {
    this.basicInfoService.basicInfo.subscribe(
      (res) => {
        this.basicInfo = res;
      }
    );
  }


  /**
   * init
   */
  initForm() {
    this.Form = this._fb.group({
      otp: ['', [Validators.required, Validators.minLength(4), Validators.pattern(this.validator.otp)]],
      password: ['', [Validators.required]],
    });
  }

  getOtp(otp: any) {
    this.Form?.get('otp')?.patchValue(otp);
  }



  onSubmit() {
    if (this.Form.invalid) {
      return;
    }
    this.loading = true;

    let payload = { ...this.Form.value, emailId: null, mobileNumber: null };

    const emailRegex = RegExp(this.validator.email);
    if (emailRegex.test(this.authService.username.username)) {
      // User entered is email
      payload.emailId = this.authService.username.username;
    } else {
      payload.mobileNumber = this.authService.username.username;
    }


    this.authService.updatePassword(payload).subscribe(
      (res: any) => {
        this.loading = false;
        this._du.openCustomSnackBar(this.setPassword ? 'Password set successfully.' : 'Password reset successfully.', 'Close', 'success');
        if (this.setPassword) {
          this.loginWithPassword();
        } else {
          this.authService.updateAuthStep('login-by-password');
          this.Form.reset();
        }
      },
      (err: any) => {
        this.loading = false;
        this._du.openCustomSnackBar(err.error.details[0], 'Close', 'error');
      }
    );

  }





  /**
  * Otp Request
  */
  onOtpRequest() {
    this.loading = true;

    const emailRegex = RegExp(this.validator.email);
    if (emailRegex.test(this.authService.username.username)) {
      // User entered is email
      this.authService
        .generateEmailOTP(this.authService.username.username).subscribe(
          (res) => {
            this.OTPSendSuccess();
            this.loading = false;
          },
          (err) => {
            this.loading = false;
            this._du.openCustomSnackBar('Error: ' + err, 'Close', 'error');
          }
        );
    } else {
      // User entered is mobile number
      this.authService
        .generateOTP(this.authService.username.username).subscribe(
          (res: any) => {
            this.OTPSendSuccess();
            this.loading = false;
          },
          (err) => {
            this.loading = false;
            this._du.openCustomSnackBar('Error: ' + err, 'Close', 'error');
          }
        );
    }
  }


  OTPSendSuccess() {
    this._du.openCustomSnackBar('OTP Sent', 'Close', 'success');
  }


  loginWithPassword(){
    this.loading = true;
    if (this.Form.invalid) {
      return;
    }

    const data ={
      userName: this.authService.username.username,
      password:this.Form.value.password,
      userRoleModel: {
        userRole :"customer"
      },
      asOTP:false
    };

    this.authService.login(data).subscribe(
    (res:any) =>{
      this.loading = false;
      this.authService.postLogin(res.token);
    },
    (err:any) =>{
      this.loading = false;
      this._du.openCustomSnackBar('Please enter correct password', 'Close', 'error');
    });
  }

}
