import { Injectable } from '@angular/core';
import { HttpConfigService } from '../../core/httpConfig/httpConfigService';
import { BehaviorSubject } from 'rxjs';
import { ICartDetails } from '../../appInterfaces/ICartDetails';
import { BasicInfoService } from '../../appServices/basic-info/basic-info.service';
import { IBasicInfo } from '../../appInterfaces/IBasicInfo';
import { IPriceTiers } from '../../appInterfaces/IPriceTiers';
import { MenuService } from '../../appServices/menu/menu.service';

@Injectable({
  providedIn: 'root'
})
export class CartService {

  api: string;
  loading = new BehaviorSubject(false);
  cart = new BehaviorSubject<any>(null);
  private cartUpdated = new BehaviorSubject<boolean>(false);
  cartUpdated$ = this.cartUpdated.asObservable();
  private basicInfo: IBasicInfo | null = null; // Store basicInfo

  constructor(
    private apiConfig: HttpConfigService,
    private menuService: MenuService,
    private basicInfoService: BasicInfoService // Inject BasicInfoService
  ) {
    this.api = this.apiConfig.getBaseUrl + 'cart-details';

    // Subscribe to BasicInfoService to get the business type
    this.basicInfoService.basicInfo.subscribe((res) => {
      this.basicInfo = res;
    });
  }

  getProductById(productId: number, item: any): void {
    this.menuService.getProductByProductId(productId).subscribe(
      (res) => {
        if (res?.priceTierModel) {
          // Update the item with the fetched priceTierModel
          item.productModel.priceTierModel = res.priceTierModel;
          // Recalculate the price using the updated priceTierModel
          this.calculatePriceFromTier(item);
        }
      },
      (err) => {
        console.error(`Error fetching product by ID ${productId}:`, err);
      }
    );
  }


  // Fetch the cart details
  getCart(): void {
    this.loading.next(true);
    this.apiConfig.get(this.api, { withCredentials: true }).subscribe(
      (res: any[]) => {
        this.loading.next(false);
        res.forEach((item) => {
          this.calculatePerItemPrice(item);
        });
        this.cart.next(res);
      },
      (err) => {
        console.error('Error fetching cart:', err);
        this.loading.next(false);
        this.cart.next(null);
      }
    );
  }

  // Clear the cart
  clearCart(): void {
    this.cart.next(null);
    this.cartUpdated.next(true); // Emit event to notify cart update
  }

  // Remove an item from the cart
  removeItem(item: ICartDetails): void {
    this.loading.next(true);
    this.apiConfig.delete(this.api + '/' + item.id, { withCredentials: true }).subscribe(
      () => {
        this.getCart();
      },
      (err) => {
        console.error('Error removing item:', err);
        this.loading.next(false);
      }
    );
  }

  // Add an item to the cart
  addToCart(item: ICartDetails): void {
    this.loading.next(true);
    this.apiConfig.post(this.api, item, { withCredentials: true }).subscribe(
      () => {
        this.getCart();
      },
      (err) => {
        console.error('Error adding item:', err);
        this.loading.next(false);
      }
    );
  }

  // Get cart details by ID
  getCartDetailById(cartId: number): ICartDetails | null {
    if (!this.cart.value) {
      return null;
    }

    for (const item of this.cart.value) {
      if (item.id === cartId) {
        return { ...item };
      }
    }
    return null;
  }

  // Calculate price for each cart item
  calculatePerItemPrice(item: any): void {
    const isCannabis = this.basicInfo?.businessType === 'cannabis';
    const hasTierPrice = item.tierPrice;
    const exceedsMaxTier = item.quantity > 3.5;

    if (isCannabis && (hasTierPrice || exceedsMaxTier)) {
      if (!item.productModel.priceTierModel) {
        // Fetch the product by ID to check for tier pricing dynamically
        this.getProductById(item.productModel.id, item);
      } else {
        // Calculate price using the existing priceTierModel
        this.calculatePriceFromTier(item);
      }
    } else {
      // Default pricing logic for non-cannabis or no tierPrice
      this.updateDefaultPrice(item);
    }
  }



  calculatePriceFromTier(item: any): void {
    const tierModel = item.productModel.priceTierModel;
    const quantity = item.quantity;

    // Define the tiers with their max quantities and prices
    const tiers = [
      { maxQuantity: 1, price: tierModel.oneGram },
      { maxQuantity: 3.5, price: tierModel.threeAndHalfGrams },
      { maxQuantity: 7, price: tierModel.sevenGrams },
      { maxQuantity: 14, price: tierModel.fourteenGrams },
      { maxQuantity: 28, price: tierModel.twentyEightGrams },
    ];

    let totalPrice = 0;

    // Calculate price based on the tiers
    for (let i = tiers.length - 1; i >= 0; i--) {
      const tier = tiers[i];
      if (quantity <= tier.maxQuantity) {
        const perGramPrice = tier.price / tier.maxQuantity;
        totalPrice = perGramPrice * quantity;
        break;
      } else if (i === tiers.length - 1) {
        // If quantity exceeds the highest tier, use the per-gram price of the 28g tier
        const perGramPrice = tier.price / tier.maxQuantity;
        totalPrice = perGramPrice * quantity;
      }
    }

    // Update item's price and per-item price
    item.price = totalPrice;
    item.pricePerItem = totalPrice / quantity;
  }






  updateDefaultPrice(item: any): void {
    let perItemPrice = item.productModel.basePrice;

    // Include add-ons and variants
    item.cartDetailsVariantModelList.forEach((variant: any) => {
      perItemPrice += variant.price;
    });
    item.cartDetailsAddOnModelList.forEach((addOn: any) => {
      perItemPrice += addOn.addOnModel.addOnPrice;
    });

    item.price = perItemPrice * item.quantity;
    item.pricePerItem = perItemPrice;
  }


  // Calculate the total price of a cart item
  calculateItemTotalPrice(item: any): number {
      if (this.basicInfo?.businessType === 'cannabis' && item.productModel.priceTierModel) {
          return item.price; // Use the pre-calculated price in cannabis
      }

      // Default logic for non-cannabis
      let basePrice = item.productModel.basePrice;

      if (item.productModel.discount) {
          basePrice = item.productModel.discount.discount;
      }

      item.cartDetailsVariantModelList.forEach((variant: any) => {
          basePrice += variant.price;
      });
      item.cartDetailsAddOnModelList.forEach((addOn: any) => {
          basePrice += addOn.addOnModel.addOnPrice;
      });

      return basePrice * item.quantity;
  }


  // Calculate the total price of the cart
  totalPriceOfCart(): number {
    let total = 0;
    if (this.cart.value) {
      this.cart.value.forEach((element: any) => {
        // Exclude items with loyalty points spent
        if (element?.loyaltyPointsSpent > 0) {
          total += 0;
        } else {
          const itemTotalPrice = this.calculateItemTotalPrice(element);
          total += itemTotalPrice;

          // Console log for debugging
        }
      });
    }

    return total;
  }

  // Calculate total loyalty points spent in the cart
  totalLoyaltyPointsSpent(): number {
    let totalPointsSpent = 0;

    if (this.cart.value) {
      this.cart.value.forEach((element: any) => {
        if (element?.loyaltyPointsSpent > 0) {
          totalPointsSpent += element.loyaltyPointsSpent;
        }
      });
    }

    return totalPointsSpent;
  }
}
